import type { GetServerSideProps, NextPage } from 'next';
import dynamic from 'next/dynamic';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { checkUserAuthOnServer } from '@mwl/core-lib';
import { defaultLocale } from '@/constants';
import { wrapperStore } from '@/store/store';
import { getNamespaces } from '@/utils';
const Layout = dynamic(() => import('@/Layouts/Layout/Layout').then(mod => mod.Layout));
const AppPage = dynamic(() => import('@/modules/app/AppPage').then(mod => mod.AppPage));
const Download: NextPage = () => {
  return <Layout data-sentry-element="Layout" data-sentry-component="Download" data-sentry-source-file="app.tsx">
      <AppPage data-sentry-element="AppPage" data-sentry-source-file="app.tsx" />
    </Layout>;
};
export const getServerSideProps: GetServerSideProps = wrapperStore.getServerSideProps(({
  dispatch
}) => async ({
  locale = defaultLocale,
  req,
  res
}) => {
  await checkUserAuthOnServer({
    dispatch,
    locale,
    res,
    req
  });
  return {
    props: {
      ...(await serverSideTranslations(locale, getNamespaces('promo')))
    }
  };
});
export default Download;